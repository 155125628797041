import {
  StoreName,
  withError,
  withLoaded,
  withLoading,
} from 'src/app/core/store/store.config';

import { createStore, withProps } from '@ngneat/elf';
import {
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';

import { Run, RunUI, ValidationResult, ValidationStatus } from './run.model';

export enum ValidationStep {
  IDLE = 'idle',
  UPLOADING_INPUTS = 'uploadingInputs',
  STARTING_VALIDATION = 'startingValidation',
  VALIDATING = 'validating',
  VALIDATED_OK = 'validatedOk',
  VALIDATED_NOK = 'validatedNok',
  VALIDATION_ERROR = 'validationError',
}

export const StatusStepMap: Record<ValidationStatus, ValidationStep> = {
  [ValidationStatus.ONGOING]: ValidationStep.VALIDATING,
  [ValidationStatus.WARNING]: ValidationStep.VALIDATED_OK,
  [ValidationStatus.ERROR]: ValidationStep.VALIDATED_NOK,
  [ValidationStatus.OK]: ValidationStep.VALIDATED_OK,
};

export type RunStoreProps = Readonly<{
  validationStep: ValidationStep;
  hasValidationErrors: boolean;
  lastValidationResults: ValidationResult[];
}>;

export const INITIAL_RUN_STORE_PROPS: RunStoreProps = {
  hasValidationErrors: false,
  lastValidationResults: [],
  validationStep: ValidationStep.IDLE,
};

export const runStore = createStore(
  { name: StoreName.Run },
  withEntities<Run, 'uuid'>({ idKey: 'uuid' }),
  withUIEntities<RunUI, 'uuid'>({ idKey: 'uuid' }),
  withActiveId(),
  withLoading(),
  withLoaded(),
  withError(),
  withProps(INITIAL_RUN_STORE_PROPS),
);
