import { CSVParserHeaderTransformations } from 'src/app/core/csv/csv.model';
import { Entity, UIEntity } from 'src/app/shared/models/entity.model';
import { FileType } from 'src/app/shared/models/file-type.model';

import { Site } from '../site/site.model';
export enum RunStatus {
  InProgress = 'in-progress',
  Successful = 'successful',
  Failed = 'failed',
  Draft = 'draft',
}

export interface RunUploadInputReq {
  readonly inputs: RunInput[];
}

export enum ValidationStatus {
  OK = 'validation-ok',
  WARNING = 'validation-warning',
  ERROR = 'validation-error',
  ONGOING = 'validation-ongoing',
}

export type Status = RunStatus | ValidationStatus;

export interface ValidationResult {
  readonly status: ValidationStatus;
  readonly message: string;
}

export interface RunValidateRsp {
  readonly validationStatus: ValidationStatus;
  readonly validationResults: ValidationResult[];
}

export type RunInput = {
  filename: string;
  type: FileType;
  putUrl?: string;
  runReferenceUuid?: string;
  libraryReferenceUuid?: string;
};

export type Run = Readonly<
  Entity & {
    siteUuid: Site['uuid'];
    status: Status;
    windCapacity: number;
    solarCapacity: number;
    bessCapacity: number;
    energyYield: number;
    npv: number;
    preferred: boolean;
    inputs: Array<RunInput>;
    outputs?: Array<RunInput>;
    optObj?: string;
    outputFileLog?: Record<keyof OutputFileLog, Array<unknown>>;
  } & Partial<RunValidateRsp>
>;

export type RunUI = UIEntity &
  Readonly<
    {
      [key in FileType]: boolean;
    } & {
      'loading-error-file': boolean;
      loadingEvents: boolean;
    }
  >;

export type OutputFileLog = Readonly<{
  bessCapacity: number;
  bessStorageDuration: number;
  bessNumberOfCyclesPerYear: number;
  windCapacity: number;
  numberOfTurbines: number;
  windHubHeight: number;
  windRotorDiameter: number;
  wakeLosses: number | 'None';
  windAvailabilityLosses: number;
  windOperationalCurtailmentLosses: number;
  windEnvironmentalLosses: number;
  windCapacityFactor: number;
  windSpecificYield: number;
  windCurtailedEnergy: number;
  solarDCCapacity: number;
  solarACCapacity: number;
  solarNumberOfInverters: number;
  solarModule: string;
  solarInverter: string;
  structure: string;
  modulesPerString: number;
  soiling: number;
  tilt: number;
  azimuth: number;
  gcr: number;
  dcAcRatio: number;
  bifacialGain: number;
  solarClipping: number;
  solarSpecificYield: number;
  solarCapacityFactor: number;
  flickerLosses: number;
  solarCurtailedEnergy: number;
  lcoe: number;
  npv: number;
  irr1: number;
  irr3: number;
  capex: number;
  omCostsYear1: number;
  aep: number;
}>;

export const OUTPUT_FILE_LOG_HEADER_MAP: CSVParserHeaderTransformations = {
  'BESS capacity (kWh)': 'bessCapacity',
  'BESS storage duration (hours)': 'bessStorageDuration',
  'BESS number of cycles per year': 'bessNumberOfCyclesPerYear',
  'Wind capacity (kW)': 'windCapacity',
  'Number of turbines': 'numberOfTurbines',
  'Wind hub height (m)': 'windHubHeight',
  'Wind rotor diameter (m)': 'windRotorDiameter',
  'Wake losses (%)': 'wakeLosses',
  'Wind availibility losses (%)': 'windAvailabilityLosses',
  'Wind operational/curtailment losses (%)': 'windOperationalCurtailmentLosses',
  'Wind environmental losses(%)': 'windEnvironmentalLosses',
  'Wind capacity factor (%)': 'windCapacityFactor',
  'Wind specific yield (kWh/kW)': 'windSpecificYield',
  'Wind curtailed energy (kWh)': 'windCurtailedEnergy',
  'Solar DC capacity (kWp)': 'solarDCCapacity',
  'Solar AC capacity (kW)': 'solarACCapacity',
  'Solar number of inverters': 'solarNumberOfInverters',
  'Solar module': 'solarModule',
  'Solar Inverter': 'solarInverter',
  Structure: 'structure',
  'Modules per string': 'modulesPerString',
  'Soiling (%)': 'soiling',
  'Tilt (°)': 'tilt',
  'Azimuth (°)': 'azimuth',
  GCR: 'gcr',
  'DC/AC ratio': 'dcAcRatio',
  'Bifacial Gain (%)': 'bifacialGain',
  'Solar clipping (%)': 'solarClipping',
  'Solar specific yield (kWh/kWp)': 'solarSpecificYield',
  'Solar capacity factor (%)': 'solarCapacityFactor',
  'Flicker losses (%)': 'flickerLosses',
  'Solar curtailed energy (kWh)': 'solarCurtailedEnergy',
  'LCOE ($/MWh)': 'lcoe',
  'NPV ($)': 'npv',
  'IRR1 (%)': 'irr1',
  'IRR3 (%)': 'irr3',
  'CAPEX ($)': 'capex',
  'O&M costs year 1 ($)': 'omCostsYear1',
  'AEP (kWh)': 'aep',
};

export interface RunFile {
  readonly type: string;
  readonly filename: string;
  readonly putUrl: string;
}
